import { FunctionComponent } from "react";
import styles from "./PopupRashawn.module.css";

type PopupRashawnType = {
  onClose?: () => void;
};

const PopupRashawn: FunctionComponent<PopupRashawnType> = ({ onClose }) => {
  return (
    <div className={styles.popupRashawn}>
      <div className={styles.vectorParent}>
        <img className={styles.frameChild} alt="" src="/rectangle-3.svg" />
        <div className={styles.frameParent}>
          <div className={styles.rectangleParent}>
            <div className={styles.frameItem} />
            <img
              className={styles.jesusCallingPodcast303FeatIcon}
              alt=""
              src="/jesuscallingpodcast303featuringandrewwcarterimg-35781089x1200-21@2x.png"
            />
            <b className={styles.rashawnCopeland}>Rashawn Copeland</b>
          </div>
          <div className={styles.frameWrapper}>
            <div className={styles.frameGroup}>
              <div className={styles.rashawnHouseParent}>
                <img
                  className={styles.rashawnHouseIcon}
                  alt=""
                  src="/house1.svg"
                />
                <div className={styles.usa}>U.S.A</div>
              </div>
              <div className={styles.rashawnHouseParent}>
                <img
                  className={styles.rashawnUsersIcon}
                  alt=""
                  src="/users1.svg"
                />
                <div className={styles.usa}>1.62M Followers</div>
              </div>
              <div className={styles.rashawnHouseParent}>
                <img
                  className={styles.rashawnCalendarIcon}
                  alt=""
                  src="/calendaralt1.svg"
                />
                <div className={styles.usa}>3/17 Joined</div>
              </div>
            </div>
          </div>
          <div className={styles.frameContainer}>
            <div className={styles.bioParent}>
              <b className={styles.usa}>Bio</b>
              <div className={styles.iTellStories}>
                I tell stories. 🗣️Digital Evangelist: @sharethekingtribe 💍
                @Ricanxo_ 📲Let’s connect
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={styles.frameDiv}>
        <div className={styles.frameParent1}>
          <div className={styles.distributionWrapper}>
            <div className={styles.distribution}>Distribution</div>
          </div>
          <div className={styles.table}>
            <div className={styles.row}>
              <div className={styles.cell} />
              <div className={styles.cell1}>
                <b className={styles.usa}>Today</b>
              </div>
              <div className={styles.cell2}>
                <b className={styles.usa}>30 Days</b>
              </div>
              <div className={styles.cell3}>
                <b className={styles.usa}>90 Days</b>
              </div>
            </div>
            <div className={styles.row1}>
              <div className={styles.cell4}>
                <b className={styles.usa}>Reach</b>
              </div>
              <div className={styles.cell5}>
                <div className={styles.usa}>201K</div>
              </div>
              <div className={styles.cell6}>
                <div className={styles.usa}>5.8M</div>
              </div>
              <div className={styles.cell7}>
                <div className={styles.usa}>16.3M</div>
              </div>
            </div>
            <div className={styles.row2}>
              <div className={styles.cell4}>
                <b className={styles.usa}>Gospel Pres.</b>
              </div>
              <div className={styles.cell5}>
                <div className={styles.usa}>11K</div>
              </div>
              <div className={styles.cell6}>
                <div className={styles.usa}>812K</div>
              </div>
              <div className={styles.cell7}>
                <div className={styles.usa}>2.3M</div>
              </div>
            </div>
          </div>
        </div>
        <div className={styles.frameParent1}>
          <div className={styles.distributionWrapper}>
            <div className={styles.distribution}>Impact</div>
          </div>
          <div className={styles.table}>
            <div className={styles.row3}>
              <div className={styles.cell12} />
              <div className={styles.cell1}>
                <b className={styles.usa}>Today</b>
              </div>
              <div className={styles.cell14}>
                <b className={styles.usa}>30 Days</b>
              </div>
              <div className={styles.cell3}>
                <b className={styles.usa}>90 Days</b>
              </div>
            </div>
            <div className={styles.row4}>
              <div className={styles.cell16}>
                <b className={styles.usa}>Church Conn.</b>
              </div>
              <div className={styles.cell5}>
                <div className={styles.usa}>120</div>
              </div>
              <div className={styles.cell18}>
                <div className={styles.usa}>2.4K</div>
              </div>
              <div className={styles.cell7}>
                <div className={styles.usa}>6.4K</div>
              </div>
            </div>
            <div className={styles.row4}>
              <div className={styles.cell16}>
                <b className={styles.usa}>Salvations</b>
              </div>
              <div className={styles.cell5}>
                <div className={styles.usa}>17</div>
              </div>
              <div className={styles.cell18}>
                <div className={styles.usa}>38</div>
              </div>
              <div className={styles.cell7}>
                <div className={styles.usa}>106</div>
              </div>
            </div>
            <div className={styles.row4}>
              <div className={styles.cell16}>
                <b className={styles.usa}>Baptisms</b>
              </div>
              <div className={styles.cell5}>
                <div className={styles.usa}>10</div>
              </div>
              <div className={styles.cell18}>
                <div className={styles.usa}>43</div>
              </div>
              <div className={styles.cell7}>
                <div className={styles.usa}>143</div>
              </div>
            </div>
            <div className={styles.row4}>
              <div className={styles.cell16}>
                <b className={styles.usa}>Reading Bible</b>
              </div>
              <div className={styles.cell5}>
                <div className={styles.usa}>71</div>
              </div>
              <div className={styles.cell30}>
                <div className={styles.usa}>236</div>
              </div>
              <div className={styles.cell7}>
                <div className={styles.usa}>785</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PopupRashawn;
