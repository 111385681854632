import { FunctionComponent } from "react";
import styles from "./PopupJoe.module.css";

type PopupJoeType = {
  onClose?: () => void;
};

const PopupJoe: FunctionComponent<PopupJoeType> = ({ onClose }) => {
  return (
    <div className={styles.popupJoe}>
      <div className={styles.vectorParent}>
        <img className={styles.frameChild} alt="" src="/rectangle-3.svg" />
        <div className={styles.frameParent}>
          <div className={styles.rectangleParent}>
            <div className={styles.frameItem} />
            <img
              className={styles.jesusCallingPodcast303FeatIcon}
              alt=""
              src="/jesuscallingpodcast303featuringandrewwcarterimg-35781089x1200-17@2x.png"
            />
            <b className={styles.joeNavarro}>Joe Navarro</b>
          </div>
          <div className={styles.frameWrapper}>
            <div className={styles.frameGroup}>
              <div className={styles.joeHouseParent}>
                <img className={styles.joeHouseIcon} alt="" src="/house1.svg" />
                <div className={styles.collegeStationTx}>
                  College Station, TX.
                </div>
              </div>
              <div className={styles.joeHouseParent}>
                <img className={styles.joeUsersIcon} alt="" src="/users1.svg" />
                <div className={styles.collegeStationTx}>1.19M Followers</div>
              </div>
              <div className={styles.joeHouseParent}>
                <img
                  className={styles.joeCalendarAltIcon}
                  alt=""
                  src="/calendaralt1.svg"
                />
                <div className={styles.collegeStationTx}>3/17 Joined</div>
              </div>
            </div>
          </div>
          <div className={styles.frameContainer}>
            <div className={styles.bioParent}>
              <b className={styles.collegeStationTx}>Bio</b>
              <div className={styles.myNameIs}>
                My name is Joe I am 23 and I make christian content!
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={styles.frameDiv}>
        <div className={styles.frameParent1}>
          <div className={styles.distributionWrapper}>
            <div className={styles.distribution}>Distribution</div>
          </div>
          <div className={styles.table}>
            <div className={styles.row}>
              <div className={styles.cell} />
              <div className={styles.cell1}>
                <b className={styles.collegeStationTx}>Today</b>
              </div>
              <div className={styles.cell2}>
                <b className={styles.collegeStationTx}>30 Days</b>
              </div>
              <div className={styles.cell3}>
                <b className={styles.collegeStationTx}>90 Days</b>
              </div>
            </div>
            <div className={styles.row1}>
              <div className={styles.cell4}>
                <b className={styles.collegeStationTx}>Reach</b>
              </div>
              <div className={styles.cell5}>
                <div className={styles.collegeStationTx}>19K</div>
              </div>
              <div className={styles.cell6}>
                <div className={styles.collegeStationTx}>4M</div>
              </div>
              <div className={styles.cell7}>
                <div className={styles.collegeStationTx}>11.9M</div>
              </div>
            </div>
            <div className={styles.row2}>
              <div className={styles.cell4}>
                <b className={styles.collegeStationTx}>Gospel Pres.</b>
              </div>
              <div className={styles.cell5}>
                <div className={styles.collegeStationTx}>11K</div>
              </div>
              <div className={styles.cell6}>
                <div className={styles.collegeStationTx}>568K</div>
              </div>
              <div className={styles.cell7}>
                <div className={styles.collegeStationTx}>1.4M</div>
              </div>
            </div>
          </div>
        </div>
        <div className={styles.frameParent1}>
          <div className={styles.distributionWrapper}>
            <div className={styles.distribution}>Impact</div>
          </div>
          <div className={styles.table}>
            <div className={styles.row3}>
              <div className={styles.cell12} />
              <div className={styles.cell1}>
                <b className={styles.collegeStationTx}>Today</b>
              </div>
              <div className={styles.cell14}>
                <b className={styles.collegeStationTx}>30 Days</b>
              </div>
              <div className={styles.cell3}>
                <b className={styles.collegeStationTx}>90 Days</b>
              </div>
            </div>
            <div className={styles.row4}>
              <div className={styles.cell16}>
                <b className={styles.collegeStationTx}>Church Conn.</b>
              </div>
              <div className={styles.cell5}>
                <div className={styles.collegeStationTx}>120</div>
              </div>
              <div className={styles.cell18}>
                <div className={styles.collegeStationTx}>3.6K</div>
              </div>
              <div className={styles.cell7}>
                <div className={styles.collegeStationTx}>4.6K</div>
              </div>
            </div>
            <div className={styles.row4}>
              <div className={styles.cell16}>
                <b className={styles.collegeStationTx}>Salvations</b>
              </div>
              <div className={styles.cell5}>
                <div className={styles.collegeStationTx}>17</div>
              </div>
              <div className={styles.cell18}>
                <div className={styles.collegeStationTx}>38</div>
              </div>
              <div className={styles.cell7}>
                <div className={styles.collegeStationTx}>106</div>
              </div>
            </div>
            <div className={styles.row4}>
              <div className={styles.cell16}>
                <b className={styles.collegeStationTx}>Baptisms</b>
              </div>
              <div className={styles.cell5}>
                <div className={styles.collegeStationTx}>10</div>
              </div>
              <div className={styles.cell18}>
                <div className={styles.collegeStationTx}>43</div>
              </div>
              <div className={styles.cell7}>
                <div className={styles.collegeStationTx}>143</div>
              </div>
            </div>
            <div className={styles.row4}>
              <div className={styles.cell16}>
                <b className={styles.collegeStationTx}>Reading Bible</b>
              </div>
              <div className={styles.cell5}>
                <div className={styles.collegeStationTx}>71</div>
              </div>
              <div className={styles.cell30}>
                <div className={styles.collegeStationTx}>236</div>
              </div>
              <div className={styles.cell7}>
                <div className={styles.collegeStationTx}>785</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PopupJoe;
