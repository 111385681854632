import { FunctionComponent } from "react";
import styles from "./PopupJoshua.module.css";

type PopupJoshuaType = {
  onClose?: () => void;
};

const PopupJoshua: FunctionComponent<PopupJoshuaType> = ({ onClose }) => {
  return (
    <div className={styles.popupJoshua}>
      <div className={styles.vectorParent}>
        <img className={styles.frameChild} alt="" src="/rectangle-3.svg" />
        <div className={styles.frameParent}>
          <div className={styles.rectangleParent}>
            <div className={styles.frameItem} />
            <img
              className={styles.joshIcon}
              alt=""
              src="/jesuscallingpodcast303featuringandrewwcarterimg-35781089x1200-1@2x.png"
            />
            <b className={styles.joshuaBroome}>Joshua Broome</b>
          </div>
          <div className={styles.frameWrapper}>
            <div className={styles.frameGroup}>
              <div className={styles.houseParent}>
                <img className={styles.houseIcon} alt="" src="/house.svg" />
                <div className={styles.california}>California</div>
              </div>
              <div className={styles.houseParent}>
                <img className={styles.usersIcon} alt="" src="/users.svg" />
                <div className={styles.california}>122K Followers</div>
              </div>
              <div className={styles.houseParent}>
                <img
                  className={styles.calendarAltIcon}
                  alt=""
                  src="/calendaralt.svg"
                />
                <div className={styles.california}>3/23 Joined</div>
              </div>
            </div>
          </div>
          <div className={styles.frameContainer}>
            <div className={styles.bioParent}>
              <b className={styles.california}>Bio</b>
              <div className={styles.basedOutOf}>
                Based out of California, Joshua is a gifted pastor, traveling
                preacher, and digital missionary.
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={styles.frameDiv}>
        <div className={styles.frameParent1}>
          <div className={styles.distributionWrapper}>
            <div className={styles.distribution}>Distribution</div>
          </div>
          <div className={styles.table}>
            <div className={styles.row}>
              <div className={styles.cell} />
              <div className={styles.cell1}>
                <b className={styles.california}>Today</b>
              </div>
              <div className={styles.cell2}>
                <b className={styles.california}>30 Days</b>
              </div>
              <div className={styles.cell3}>
                <b className={styles.california}>90 Days</b>
              </div>
            </div>
            <div className={styles.row1}>
              <div className={styles.cell4}>
                <b className={styles.california}>Reach</b>
              </div>
              <div className={styles.cell5}>
                <div className={styles.california}>19K</div>
              </div>
              <div className={styles.cell6}>
                <div className={styles.california}>5.2M</div>
              </div>
              <div className={styles.cell7}>
                <div className={styles.california}>14.9M</div>
              </div>
            </div>
            <div className={styles.row2}>
              <div className={styles.cell4}>
                <b className={styles.california}>Gospel Pres.</b>
              </div>
              <div className={styles.cell5}>
                <div className={styles.california}>11K</div>
              </div>
              <div className={styles.cell6}>
                <div className={styles.california}>650K</div>
              </div>
              <div className={styles.cell7}>
                <div className={styles.california}>1.9M</div>
              </div>
            </div>
          </div>
        </div>
        <div className={styles.frameParent1}>
          <div className={styles.distributionWrapper}>
            <div className={styles.distribution}>Impact</div>
          </div>
          <div className={styles.table}>
            <div className={styles.row3}>
              <div className={styles.cell12} />
              <div className={styles.cell1}>
                <b className={styles.california}>Today</b>
              </div>
              <div className={styles.cell14}>
                <b className={styles.california}>30 Days</b>
              </div>
              <div className={styles.cell3}>
                <b className={styles.california}>90 Days</b>
              </div>
            </div>
            <div className={styles.row4}>
              <div className={styles.cell16}>
                <b className={styles.california}>Church Conn.</b>
              </div>
              <div className={styles.cell5}>
                <div className={styles.california}>500</div>
              </div>
              <div className={styles.cell18}>
                <div className={styles.california}>1.8K</div>
              </div>
              <div className={styles.cell7}>
                <div className={styles.california}>5.8K</div>
              </div>
            </div>
            <div className={styles.row4}>
              <div className={styles.cell16}>
                <b className={styles.california}>Salvations</b>
              </div>
              <div className={styles.cell5}>
                <div className={styles.california}>17</div>
              </div>
              <div className={styles.cell18}>
                <div className={styles.california}>38</div>
              </div>
              <div className={styles.cell7}>
                <div className={styles.california}>106</div>
              </div>
            </div>
            <div className={styles.row4}>
              <div className={styles.cell16}>
                <b className={styles.california}>Baptisms</b>
              </div>
              <div className={styles.cell5}>
                <div className={styles.california}>10</div>
              </div>
              <div className={styles.cell18}>
                <div className={styles.california}>43</div>
              </div>
              <div className={styles.cell7}>
                <div className={styles.california}>143</div>
              </div>
            </div>
            <div className={styles.row4}>
              <div className={styles.cell16}>
                <b className={styles.california}>Reading Bible</b>
              </div>
              <div className={styles.cell5}>
                <div className={styles.california}>71</div>
              </div>
              <div className={styles.cell30}>
                <div className={styles.california}>236</div>
              </div>
              <div className={styles.cell7}>
                <div className={styles.california}>785</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PopupJoshua;
