import { FunctionComponent, useState, useCallback } from "react";
import Filters from "../components/Filters";
import PortalDrawer from "../components/PortalDrawer";
import { useNavigate } from "react-router-dom";
import PopupMarcus from "../components/PopupMarcus";
import PortalPopup from "../components/PortalPopup";
import PopupRashawn from "../components/PopupRashawn";
import PopupJoshua from "../components/PopupJoshua";
import PopupBen from "../components/PopupBen";
import PopupAndrew from "../components/PopupAndrew";
import PopupJoe from "../components/PopupJoe";
import PopupMadelyn from "../components/PopupMadelyn";
import styles from "./Dashboard6.module.css";
const Dashboard6: FunctionComponent = () => {
  const [isFilters1Open, setFilters1Open] = useState(false);
  const [isFiltersOpen, setFiltersOpen] = useState(false);
  const navigate = useNavigate();
  const [isPopupMarcus1Open, setPopupMarcus1Open] = useState(false);
  const [isPopupMarcusOpen, setPopupMarcusOpen] = useState(false);
  const [isPopupRashawn1Open, setPopupRashawn1Open] = useState(false);
  const [isPopupRashawnOpen, setPopupRashawnOpen] = useState(false);
  const [isPopupJoshua1Open, setPopupJoshua1Open] = useState(false);
  const [isPopupJoshuaOpen, setPopupJoshuaOpen] = useState(false);
  const [isPopupBen1Open, setPopupBen1Open] = useState(false);
  const [isPopupBenOpen, setPopupBenOpen] = useState(false);
  const [isPopupAndrew1Open, setPopupAndrew1Open] = useState(false);
  const [isPopupAndrewOpen, setPopupAndrewOpen] = useState(false);
  const [isPopupJoe1Open, setPopupJoe1Open] = useState(false);
  const [isPopupJoeOpen, setPopupJoeOpen] = useState(false);
  const [isPopupMadelyn1Open, setPopupMadelyn1Open] = useState(false);
  const [isPopupMadelynOpen, setPopupMadelynOpen] = useState(false);

  const openFilters1 = useCallback(() => {
    setFilters1Open(true);
  }, []);

  const closeFilters1 = useCallback(() => {
    setFilters1Open(false);
  }, []);

  const openFilters = useCallback(() => {
    setFiltersOpen(true);
  }, []);

  const closeFilters = useCallback(() => {
    setFiltersOpen(false);
  }, []);

  const onFrameButtonClick = useCallback(() => {
    navigate("/");
  }, [navigate]);

  const onFrameButton1Click = useCallback(() => {
    navigate("/dashboard-90");
  }, [navigate]);

  const openPopupMarcus1 = useCallback(() => {
    setPopupMarcus1Open(true);
  }, []);

  const closePopupMarcus1 = useCallback(() => {
    setPopupMarcus1Open(false);
  }, []);

  const openPopupMarcus = useCallback(() => {
    setPopupMarcusOpen(true);
  }, []);

  const closePopupMarcus = useCallback(() => {
    setPopupMarcusOpen(false);
  }, []);

  const openPopupRashawn1 = useCallback(() => {
    setPopupRashawn1Open(true);
  }, []);

  const closePopupRashawn1 = useCallback(() => {
    setPopupRashawn1Open(false);
  }, []);

  const openPopupRashawn = useCallback(() => {
    setPopupRashawnOpen(true);
  }, []);

  const closePopupRashawn = useCallback(() => {
    setPopupRashawnOpen(false);
  }, []);

  const openPopupJoshua1 = useCallback(() => {
    setPopupJoshua1Open(true);
  }, []);

  const closePopupJoshua1 = useCallback(() => {
    setPopupJoshua1Open(false);
  }, []);

  const openPopupJoshua = useCallback(() => {
    setPopupJoshuaOpen(true);
  }, []);

  const closePopupJoshua = useCallback(() => {
    setPopupJoshuaOpen(false);
  }, []);

  const openPopupBen1 = useCallback(() => {
    setPopupBen1Open(true);
  }, []);

  const closePopupBen1 = useCallback(() => {
    setPopupBen1Open(false);
  }, []);

  const openPopupBen = useCallback(() => {
    setPopupBenOpen(true);
  }, []);

  const closePopupBen = useCallback(() => {
    setPopupBenOpen(false);
  }, []);

  const openPopupAndrew1 = useCallback(() => {
    setPopupAndrew1Open(true);
  }, []);

  const closePopupAndrew1 = useCallback(() => {
    setPopupAndrew1Open(false);
  }, []);

  const openPopupAndrew = useCallback(() => {
    setPopupAndrewOpen(true);
  }, []);

  const closePopupAndrew = useCallback(() => {
    setPopupAndrewOpen(false);
  }, []);

  const openPopupJoe1 = useCallback(() => {
    setPopupJoe1Open(true);
  }, []);

  const closePopupJoe1 = useCallback(() => {
    setPopupJoe1Open(false);
  }, []);

  const openPopupJoe = useCallback(() => {
    setPopupJoeOpen(true);
  }, []);

  const closePopupJoe = useCallback(() => {
    setPopupJoeOpen(false);
  }, []);

  const openPopupMadelyn1 = useCallback(() => {
    setPopupMadelyn1Open(true);
  }, []);

  const closePopupMadelyn1 = useCallback(() => {
    setPopupMadelyn1Open(false);
  }, []);

  const openPopupMadelyn = useCallback(() => {
    setPopupMadelynOpen(true);
  }, []);

  const closePopupMadelyn = useCallback(() => {
    setPopupMadelynOpen(false);
  }, []);

  return (
    <>
      <main className={styles.dashboard6}>
        <img className={styles.vectorIcon} alt="" src="/vector@2x.jpg" />
        <div className={styles.dashboard6Inner}>
          <div className={styles.frameParent}>
            <div className={styles.shareTheKing1Parent}>
              <img
                className={styles.shareTheKing1Icon}
                alt=""
                src="/sharetheking-1@2x.png"
              />
              <h1 className={styles.metricsLeaderboard}>METRICS LEADERBOARD</h1>
            </div>
            <div className={styles.clockParent}>
              <img className={styles.clockIcon} alt="" src="/clock.svg" />
              <h1 className={styles.amCdt}>9:12 AM (CDT)</h1>
            </div>
          </div>
        </div>
        <div className={styles.frameGroup}>
          <div className={styles.frameContainer}>
            <div className={styles.filtersWrapper}>
              <div className={styles.filters}>Filters</div>
            </div>
            <button className={styles.mobileFilters} onClick={openFilters1}>
              <img
                className={styles.sliders6Icon}
                alt=""
                src="/sliders-1@2x.png"
                onClick={openFilters}
              />
            </button>
            <div className={styles.frameDiv}>
              <button
                className={styles.daysWrapper}
                onClick={onFrameButtonClick}
              >
                <div className={styles.days}>30 Days</div>
              </button>
              <button
                className={styles.daysContainer}
                onClick={onFrameButton1Click}
              >
                <div className={styles.days}>90 Days</div>
              </button>
              <button className={styles.monthsWrapper}>
                <div className={styles.months}>6 Months</div>
              </button>
            </div>
          </div>
          <div className={styles.table}>
            <div className={styles.row}>
              <div className={styles.cell}>
                <div className={styles.filters}>Photo</div>
              </div>
              <div className={styles.cell1}>
                <div className={styles.filters}>Name</div>
                <img
                  className={styles.caretDownIcon}
                  alt=""
                  src="/caretdown.svg"
                />
              </div>
              <div className={styles.cell2}>
                <div className={styles.filters}>Reach</div>
                <img
                  className={styles.caretDownIcon}
                  alt=""
                  src="/caretdown.svg"
                />
              </div>
              <div className={styles.cell3}>
                <div className={styles.filters}>Gospel PR.</div>
                <img
                  className={styles.caretDownIcon}
                  alt=""
                  src="/caretdown.svg"
                />
              </div>
              <div className={styles.cell4}>
                <div className={styles.filters}>Church Conn.</div>
                <img
                  className={styles.caretDownIcon}
                  alt=""
                  src="/caretdown.svg"
                />
              </div>
              <div className={styles.cell5}>
                <div className={styles.filters}>Prayer</div>
                <img
                  className={styles.caretDownIcon}
                  alt=""
                  src="/caretdown.svg"
                />
              </div>
              <div className={styles.cell6}>
                <div className={styles.filters}>Growth</div>
                <img
                  className={styles.caretDownIcon}
                  alt=""
                  src="/caretdown.svg"
                />
              </div>
            </div>
            <a className={styles.row1} onClick={openPopupMarcus1}>
              <a className={styles.cell7} onClick={openPopupMarcus}>
                <img
                  className={styles.marcusIcon}
                  alt=""
                  src="/jesuscallingpodcast303featuringandrewwcarterimg-35781089x1200-11@2x.png"
                />
              </a>
              <div className={styles.cell8}>
                <div className={styles.marcusStanley}>Marcus Stanley</div>
              </div>
              <div className={styles.cell9}>
                <div className={styles.marcusStanley}>33.8M</div>
              </div>
              <div className={styles.cell10}>
                <div className={styles.marcusStanley}>4.3M</div>
              </div>
              <div className={styles.cell11}>
                <div className={styles.marcusStanley}>12.3K</div>
              </div>
              <div className={styles.cell12}>
                <div className={styles.marcusStanley}>22.1K</div>
              </div>
              <div className={styles.cell13}>
                <div className={styles.marcusStanley}>3%</div>
              </div>
            </a>
            <a className={styles.row2} onClick={openPopupRashawn1}>
              <a className={styles.cell7} onClick={openPopupRashawn}>
                <img
                  className={styles.marcusIcon}
                  alt=""
                  src="/jesuscallingpodcast303featuringandrewwcarterimg-35781089x1200-111@2x.png"
                />
              </a>
              <div className={styles.cell8}>
                <div className={styles.marcusStanley}>Rashawn Copeland</div>
              </div>
              <div className={styles.cell9}>
                <div className={styles.marcusStanley}>32.3M</div>
              </div>
              <div className={styles.cell10}>
                <div className={styles.marcusStanley}>4.4M</div>
              </div>
              <div className={styles.cell11}>
                <div className={styles.marcusStanley}>12.6K</div>
              </div>
              <div className={styles.cell12}>
                <div className={styles.marcusStanley}>21.9K</div>
              </div>
              <div className={styles.cell20}>
                <div className={styles.marcusStanley}>3%</div>
              </div>
            </a>
            <a className={styles.row3} onClick={openPopupJoshua1}>
              <div className={styles.cell21} onClick={openPopupJoshua}>
                <img
                  className={styles.marcusIcon}
                  alt=""
                  src="/jesuscallingpodcast303featuringandrewwcarterimg-35781089x1200-12@2x.png"
                />
              </div>
              <div className={styles.cell8}>
                <div className={styles.marcusStanley}>Joshua Broome</div>
              </div>
              <div className={styles.cell9}>
                <div className={styles.marcusStanley}>30.9M</div>
              </div>
              <div className={styles.cell10}>
                <div className={styles.marcusStanley}>4.1M</div>
              </div>
              <div className={styles.cell11}>
                <div className={styles.marcusStanley}>11.8K</div>
              </div>
              <div className={styles.cell12}>
                <div className={styles.marcusStanley}>20.3K</div>
              </div>
              <div className={styles.cell13}>
                <div className={styles.marcusStanley}>2%</div>
              </div>
            </a>
            <a className={styles.row3} onClick={openPopupBen1}>
              <a className={styles.cell7} onClick={openPopupBen}>
                <img
                  className={styles.marcusIcon}
                  alt=""
                  src="/jesuscallingpodcast303featuringandrewwcarterimg-35781089x1200-13@2x.png"
                />
              </a>
              <div className={styles.cell8}>
                <div className={styles.marcusStanley}>Ben Bennett</div>
              </div>
              <div className={styles.cell9}>
                <div className={styles.marcusStanley}>28.1M</div>
              </div>
              <div className={styles.cell10}>
                <div className={styles.marcusStanley}>3.7M</div>
              </div>
              <div className={styles.cell11}>
                <div className={styles.marcusStanley}>10.7K</div>
              </div>
              <div className={styles.cell12}>
                <div className={styles.marcusStanley}>18.7K</div>
              </div>
              <div className={styles.cell13}>
                <div className={styles.marcusStanley}>5%</div>
              </div>
            </a>
            <a className={styles.row3} onClick={openPopupAndrew1}>
              <a className={styles.cell7} onClick={openPopupAndrew}>
                <img
                  className={styles.marcusIcon}
                  alt=""
                  src="/jesuscallingpodcast303featuringandrewwcarterimg-35781089x1200-14@2x.png"
                />
              </a>
              <div className={styles.cell8}>
                <div className={styles.marcusStanley}>Andrew Carter</div>
              </div>
              <div className={styles.cell9}>
                <div className={styles.marcusStanley}>24.7M</div>
              </div>
              <div className={styles.cell10}>
                <div className={styles.marcusStanley}>3.5M</div>
              </div>
              <div className={styles.cell11}>
                <div className={styles.marcusStanley}>9.5K</div>
              </div>
              <div className={styles.cell12}>
                <div className={styles.marcusStanley}>17.1K</div>
              </div>
              <div className={styles.cell13}>
                <div className={styles.marcusStanley}>3%</div>
              </div>
            </a>
            <a className={styles.row3} onClick={openPopupJoe1}>
              <a className={styles.cell7} onClick={openPopupJoe}>
                <img
                  className={styles.marcusIcon}
                  alt=""
                  src="/jesuscallingpodcast303featuringandrewwcarterimg-35781089x1200-15@2x.png"
                />
              </a>
              <div className={styles.cell8}>
                <div className={styles.marcusStanley}>Joe Navarro</div>
              </div>
              <div className={styles.cell9}>
                <div className={styles.marcusStanley}>22.9M</div>
              </div>
              <div className={styles.cell10}>
                <div className={styles.marcusStanley}>3.1M</div>
              </div>
              <div className={styles.cell11}>
                <div className={styles.marcusStanley}>8.6K</div>
              </div>
              <div className={styles.cell12}>
                <div className={styles.marcusStanley}>20.6K</div>
              </div>
              <div className={styles.cell13}>
                <div className={styles.marcusStanley}>4%</div>
              </div>
            </a>
            <a className={styles.row3} onClick={openPopupMadelyn1}>
              <a className={styles.cell7} onClick={openPopupMadelyn}>
                <img
                  className={styles.marcusIcon}
                  alt=""
                  src="/jesuscallingpodcast303featuringandrewwcarterimg-35781089x1200-16@2x.png"
                />
              </a>
              <div className={styles.cell8}>
                <div className={styles.marcusStanley}>Madelyn Carcuap</div>
              </div>
              <div className={styles.cell9}>
                <div className={styles.marcusStanley}>20.7M</div>
              </div>
              <div className={styles.cell10}>
                <div className={styles.marcusStanley}>3.2M</div>
              </div>
              <div className={styles.cell11}>
                <div className={styles.marcusStanley}>7.9K</div>
              </div>
              <div className={styles.cell12}>
                <div className={styles.marcusStanley}>18.9K</div>
              </div>
              <div className={styles.cell13}>
                <div className={styles.marcusStanley}>5%</div>
              </div>
            </a>
          </div>
        </div>
      </main>
      {isFilters1Open && (
        <PortalDrawer
          overlayColor="rgba(113, 113, 113, 0.3)"
          placement="Right"
          onOutsideClick={closeFilters1}
        >
          <Filters onClose={closeFilters1} />
        </PortalDrawer>
      )}
      {isFiltersOpen && (
        <PortalDrawer
          overlayColor="rgba(113, 113, 113, 0.3)"
          placement="Right"
          onOutsideClick={closeFilters}
        >
          <Filters onClose={closeFilters} />
        </PortalDrawer>
      )}
      {isPopupMarcus1Open && (
        <PortalPopup
          overlayColor="rgba(113, 113, 113, 0.3)"
          placement="Centered"
          onOutsideClick={closePopupMarcus1}
        >
          <PopupMarcus onClose={closePopupMarcus1} />
        </PortalPopup>
      )}
      {isPopupMarcusOpen && (
        <PortalPopup
          overlayColor="rgba(113, 113, 113, 0.3)"
          placement="Centered"
          onOutsideClick={closePopupMarcus}
        >
          <PopupMarcus onClose={closePopupMarcus} />
        </PortalPopup>
      )}
      {isPopupRashawn1Open && (
        <PortalPopup
          overlayColor="rgba(113, 113, 113, 0.3)"
          placement="Centered"
          onOutsideClick={closePopupRashawn1}
        >
          <PopupRashawn onClose={closePopupRashawn1} />
        </PortalPopup>
      )}
      {isPopupRashawnOpen && (
        <PortalPopup
          overlayColor="rgba(113, 113, 113, 0.3)"
          placement="Centered"
          onOutsideClick={closePopupRashawn}
        >
          <PopupRashawn onClose={closePopupRashawn} />
        </PortalPopup>
      )}
      {isPopupJoshua1Open && (
        <PortalPopup
          overlayColor="rgba(113, 113, 113, 0.3)"
          placement="Centered"
          onOutsideClick={closePopupJoshua1}
        >
          <PopupJoshua onClose={closePopupJoshua1} />
        </PortalPopup>
      )}
      {isPopupJoshuaOpen && (
        <PortalPopup
          overlayColor="rgba(113, 113, 113, 0.3)"
          placement="Centered"
          onOutsideClick={closePopupJoshua}
        >
          <PopupJoshua onClose={closePopupJoshua} />
        </PortalPopup>
      )}
      {isPopupBen1Open && (
        <PortalPopup
          overlayColor="rgba(113, 113, 113, 0.3)"
          placement="Centered"
          onOutsideClick={closePopupBen1}
        >
          <PopupBen onClose={closePopupBen1} />
        </PortalPopup>
      )}
      {isPopupBenOpen && (
        <PortalPopup
          overlayColor="rgba(113, 113, 113, 0.3)"
          placement="Centered"
          onOutsideClick={closePopupBen}
        >
          <PopupBen onClose={closePopupBen} />
        </PortalPopup>
      )}
      {isPopupAndrew1Open && (
        <PortalPopup
          overlayColor="rgba(113, 113, 113, 0.3)"
          placement="Centered"
          onOutsideClick={closePopupAndrew1}
        >
          <PopupAndrew onClose={closePopupAndrew1} />
        </PortalPopup>
      )}
      {isPopupAndrewOpen && (
        <PortalPopup
          overlayColor="rgba(113, 113, 113, 0.3)"
          placement="Centered"
          onOutsideClick={closePopupAndrew}
        >
          <PopupAndrew onClose={closePopupAndrew} />
        </PortalPopup>
      )}
      {isPopupJoe1Open && (
        <PortalPopup
          overlayColor="rgba(113, 113, 113, 0.3)"
          placement="Centered"
          onOutsideClick={closePopupJoe1}
        >
          <PopupJoe onClose={closePopupJoe1} />
        </PortalPopup>
      )}
      {isPopupJoeOpen && (
        <PortalPopup
          overlayColor="rgba(113, 113, 113, 0.3)"
          placement="Centered"
          onOutsideClick={closePopupJoe}
        >
          <PopupJoe onClose={closePopupJoe} />
        </PortalPopup>
      )}
      {isPopupMadelyn1Open && (
        <PortalPopup
          overlayColor="rgba(113, 113, 113, 0.3)"
          placement="Centered"
          onOutsideClick={closePopupMadelyn1}
        >
          <PopupMadelyn onClose={closePopupMadelyn1} />
        </PortalPopup>
      )}
      {isPopupMadelynOpen && (
        <PortalPopup
          overlayColor="rgba(113, 113, 113, 0.3)"
          placement="Centered"
          onOutsideClick={closePopupMadelyn}
        >
          <PopupMadelyn onClose={closePopupMadelyn} />
        </PortalPopup>
      )}
    </>
  );
};

export default Dashboard6;
