import { FunctionComponent } from "react";
import styles from "./PopupBen.module.css";

type PopupBenType = {
  onClose?: () => void;
};

const PopupBen: FunctionComponent<PopupBenType> = ({ onClose }) => {
  return (
    <div className={styles.popupBen}>
      <div className={styles.vectorParent}>
        <img className={styles.frameChild} alt="" src="/rectangle-3.svg" />
        <div className={styles.frameParent}>
          <div className={styles.rectangleParent}>
            <div className={styles.frameItem} />
            <img
              className={styles.jesusCallingPodcast303FeatIcon}
              alt=""
              src="/jesuscallingpodcast303featuringandrewwcarterimg-35781089x1200-2@2x.png"
            />
            <b className={styles.benBennett}>Ben Bennett</b>
          </div>
          <div className={styles.frameWrapper}>
            <div className={styles.frameGroup}>
              <div className={styles.benHouseParent}>
                <img className={styles.benHouseIcon} alt="" src="/house1.svg" />
                <div className={styles.losAngelesCa}>Los Angeles, CA.</div>
              </div>
              <div className={styles.benHouseParent}>
                <img className={styles.benUsersIcon} alt="" src="/users1.svg" />
                <div className={styles.losAngelesCa}>34.1K Followers</div>
              </div>
              <div className={styles.benHouseParent}>
                <img
                  className={styles.benCalendarAltIcon}
                  alt=""
                  src="/calendaralt1.svg"
                />
                <div className={styles.losAngelesCa}>3/17 Joined</div>
              </div>
            </div>
          </div>
          <div className={styles.frameContainer}>
            <div className={styles.bioParent}>
              <b className={styles.losAngelesCa}>Bio</b>
              <div className={styles.bestsellingAuthorOf}>
                🖋 Bestselling Author of Free To Thrive w/ @harpercollins 🏴
                Leading @ResolutionMovement 🎤 Communicator @JoshdMcdowell
                Ministry
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={styles.frameDiv}>
        <div className={styles.frameParent1}>
          <div className={styles.distributionWrapper}>
            <div className={styles.distribution}>Distribution</div>
          </div>
          <div className={styles.table}>
            <div className={styles.row}>
              <div className={styles.cell} />
              <div className={styles.cell1}>
                <b className={styles.losAngelesCa}>Today</b>
              </div>
              <div className={styles.cell2}>
                <b className={styles.losAngelesCa}>30 Days</b>
              </div>
              <div className={styles.cell3}>
                <b className={styles.losAngelesCa}>90 Days</b>
              </div>
            </div>
            <div className={styles.row1}>
              <div className={styles.cell4}>
                <b className={styles.losAngelesCa}>Reach</b>
              </div>
              <div className={styles.cell5}>
                <div className={styles.losAngelesCa}>19K</div>
              </div>
              <div className={styles.cell6}>
                <div className={styles.losAngelesCa}>4.7M</div>
              </div>
              <div className={styles.cell7}>
                <div className={styles.losAngelesCa}>14.1M</div>
              </div>
            </div>
            <div className={styles.row2}>
              <div className={styles.cell4}>
                <b className={styles.losAngelesCa}>Gospel Pres.</b>
              </div>
              <div className={styles.cell5}>
                <div className={styles.losAngelesCa}>11K</div>
              </div>
              <div className={styles.cell6}>
                <div className={styles.losAngelesCa}>611K</div>
              </div>
              <div className={styles.cell7}>
                <div className={styles.losAngelesCa}>1.7M</div>
              </div>
            </div>
          </div>
        </div>
        <div className={styles.frameParent1}>
          <div className={styles.distributionWrapper}>
            <div className={styles.distribution}>Impact</div>
          </div>
          <div className={styles.table}>
            <div className={styles.row3}>
              <div className={styles.cell12} />
              <div className={styles.cell1}>
                <b className={styles.losAngelesCa}>Today</b>
              </div>
              <div className={styles.cell14}>
                <b className={styles.losAngelesCa}>30 Days</b>
              </div>
              <div className={styles.cell3}>
                <b className={styles.losAngelesCa}>90 Days</b>
              </div>
            </div>
            <div className={styles.row4}>
              <div className={styles.cell16}>
                <b className={styles.losAngelesCa}>Church Conn.</b>
              </div>
              <div className={styles.cell5}>
                <div className={styles.losAngelesCa}>120</div>
              </div>
              <div className={styles.cell18}>
                <div className={styles.losAngelesCa}>1.7K</div>
              </div>
              <div className={styles.cell7}>
                <div className={styles.losAngelesCa}>4.7K</div>
              </div>
            </div>
            <div className={styles.row4}>
              <div className={styles.cell16}>
                <b className={styles.losAngelesCa}>Salvations</b>
              </div>
              <div className={styles.cell5}>
                <div className={styles.losAngelesCa}>17</div>
              </div>
              <div className={styles.cell18}>
                <div className={styles.losAngelesCa}>38</div>
              </div>
              <div className={styles.cell7}>
                <div className={styles.losAngelesCa}>106</div>
              </div>
            </div>
            <div className={styles.row4}>
              <div className={styles.cell16}>
                <b className={styles.losAngelesCa}>Baptisms</b>
              </div>
              <div className={styles.cell5}>
                <div className={styles.losAngelesCa}>10</div>
              </div>
              <div className={styles.cell18}>
                <div className={styles.losAngelesCa}>43</div>
              </div>
              <div className={styles.cell7}>
                <div className={styles.losAngelesCa}>143</div>
              </div>
            </div>
            <div className={styles.row4}>
              <div className={styles.cell16}>
                <b className={styles.losAngelesCa}>Reading Bible</b>
              </div>
              <div className={styles.cell5}>
                <div className={styles.losAngelesCa}>71</div>
              </div>
              <div className={styles.cell30}>
                <div className={styles.losAngelesCa}>236</div>
              </div>
              <div className={styles.cell7}>
                <div className={styles.losAngelesCa}>785</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PopupBen;
